import { css } from 'styled-components';

export const theme = {
  color: {
    primary: '#003f87',
    primaryLight: '#E3F2FF',
    primaryDark: '#0666B9',
    white: '#fff',
    whiteSecondary: '#fcfcfc',
    altHover: '#FFFFFFCC',
    black: '#000',
    bgLight: '#F1F8FE',
    lightGrey: '#B5B5B5',
    darkGrey: '#737373',
    errorColor: '#f00',
    orangeUnderline: '#ffba3b',
    passion: '#EF5B5B',
    redUnderline: '#F25856',
    red: '#FF0000',
    redDark: '#dd2c28',
    greyBg: '#F3F4F8',
    textLight: '#313131',
    bgPrimary: '#003d8a',
    bgSecondary: '#F2F5F9',
    textLightSecond: '#666666',
    paginationStroke: '#C4CDD5',
    sliderDotsLight: '#F2F5F9',
    fillPaginateDisable: '#919EAB',
    paginateBorder: '#DFE3E8',
    bgBlue: '#223D7C',
    borderLight: '#e1e1e1',
    socialMediaBg: '#F2F4F8',
    scrollTrackBg: '#b3b3b3',
    darkBlue: '#123673',
    greyText: '#9B9B9B',
    skeletonGrey: '#DDDBDD',
  },
  fontStyleMedium: css`
    font-family: Poppins-Medium;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  `,
  fontStyleRegular: css`
    font-family: Poppins-Regular;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  `,
  fontStyleBold: css`
    font-family: Poppins-Bold;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  `,
};
